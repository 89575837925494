import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: () => import("@/views/mtHome/index.vue")
    },
    {
        path: '/threelist',
        name: 'Three',
        component: () => import("@/views/threeList/index.vue")
    },
    {
        path: '/demo',
        name: 'Demo',
        component: () => import("@/views/demo/index.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
