import { createStore } from "vuex";
import { operateList } from "@/utils/config";

export interface IChild {
    name: string;
    value: string;
    selected: string;
    checked: Boolean
    state: number
}
export interface IOperateModels {
    selModel: IChild[];
    moveModel: IChild[];
    placeModel: IChild[];
    Measurement: IChild[];
    dissectionModel: IChild[];
    modelAnimation: IChild[];
}



interface ISelectList {
    selectList: IOperateModels
    defaultSel: Number,
    Properties: string,
    showList: number,
    navigateCheck: string,
    showPanel: boolean,
    currentFunctionality: string
    operate: string
    operateName: string
    projectName: string
    selectModel: number
    nowLocation: string
    isRomovePath: boolean
    videoSrc: string
}

export default createStore<ISelectList>({
    state: {
        selectList: operateList,
        defaultSel: 0,
        Properties: '',
        showList: 0,
        navigateCheck: '',
        showPanel: false,
        currentFunctionality: '',
        operate: '',
        operateName: '',
        projectName: '',
        selectModel: -1,
        nowLocation: '',
        isRomovePath: false,
        videoSrc: '',
    },
    getters: {
    },
    mutations: {
        /**
         * 修改选择
         */
        setSelModel(state, data) {
            state.selectList.selModel = data
        },
        /**
         * 拖拽修改
         */
        setMoveModel(state, data) {
            state.selectList.moveModel = data
        },
        /**
         * 点摆放
         */
        setPlaceModel(state, data) {
            state.selectList.placeModel = data
        },
        /**
         * 测量
         */
        setMeasurement(state, data) {
            state.selectList.Measurement = data
        },
        /**
         * 剖切
         */
        setDissectionModel(state, data) {
            state.selectList.dissectionModel = data
        },
        /**
         * 动画
         */
        setModelAnimation(state, data) {
            state.selectList.modelAnimation = data
        },
        setdefaultSel(state, data) {
            state.defaultSel = data
        },
        setProperties(state, data) {
            state.Properties = data
        },
        setShowList(state, data) {
            state.showList = data
        },
        setNavigateCheck(state, data) {
            state.navigateCheck = data
        },
        setShowPanel(state, data) {
            state.showPanel = data
        },
        setCurrentFunctionality(state, data) {
            state.currentFunctionality = data
        },
        setOperate(state, data) {
            state.operate = data
        },
        setOperateName(state, data) {
            state.operateName = data
        },
        setProject(state, data) {
            state.projectName = data
        },
        setSelectModel(state, data) {
            state.selectModel = data
        },
        setNowLocation(state, data) {
            state.nowLocation = data
        },
        setIsRomovePath(state, data) {
            state.isRomovePath = data
        },
        setVideoSrc(state, data) {
            state.videoSrc = data
        }
    },
    actions: {
    },
    modules: {
    }
})
