import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import VueCookies from 'vue3-cookies'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

createApp(App)
    .use(store)
    .use(router)
    .use(Vue3ColorPicker)
    .use(VueCookies)
    .mount('#app')
