// , '默认天空盒'
const ETopPapers = [
    {
        name: '文件',
        child: ['加载预设场景', '清空项目数据']
    }, {
        name: '环境',
        child: ['天气设置', '天空盒设置']
    }, {
        name: '视角',
        child: ['回到初始视角', '视角设置']
    }, {
        name: '资源',
        child: ['3D动效编辑']
    }, {
        name: '保存'
    },
]

const Effect = [
    {
        name: '模型库'
    },
    {
        name: '水面',
    },
    {
        name: '标签'
    },
    {
        name: '3D动效'
    },
    {
        name: '视频'
    },
    {
        name: '数据面板'
    }
]

// //底部配置
// enum Effect {
//     model = ,
//     lable = '标签',
//     flyLine = '飞线',
//     areaOutline = '区域轮廓',
//     diffusion = '扩散光波',
//     water = '水面',
//     particle = '粒子特效',
//     video = '视频',
//     dataList = '数据面板'
// }

// // 模型库
// enum EffectChild {
//     people = ,
//     facility = '设备设施',
//     architecture = '建筑',
//     landscape = '景观小品',
//     myModel = '我的模型'
// }

//右侧侧边栏配置
enum EInteraction {
    attribut = '属性',
    interaction = '交互'
}


//侧边栏功能
const operateList = {
    selModel: [{ name: '单击选择模型', value: 'choose', selected: 'inchoose', checked: true, state: 0 },
    { name: '单击按钮，选择模型移动', value: 'move', selected: 'inmove', checked: false, state: 0 },
    { name: '单击按钮，选择模型旋转', value: 'rotate', selected: 'inrotate', checked: false, state: 0 },
    { name: '单击按钮，选择模型缩放', value: 'zoom', selected: 'inzoom', checked: false, state: 0 },
    { name: '单击按钮，选择模型，点摆放', value: 'point', selected: 'inpoint', checked: false, state: 0 },
    { name: '单击按钮，选择模型，折线摆放', value: 'polyline', selected: 'inpolyline', checked: false, state: 0 },
    { name: '测量', value: 'Measurement', selected: 'inMeasurement', checked: false, state: 0 },
    { name: '查看模型剖面', value: 'cutting', selected: 'incutting', checked: false, state: 0 },
    { name: '查看模型内部构件', value: 'explode', selected: 'inexplode', checked: false, state: 0 },
    { name: '选择模型，添加动画', value: 'path', selected: 'inpath', checked: false, state: 0 }],
    moveModel: [
    ],
    placeModel: [
    ],
    Measurement: [],
    dissectionModel: [
        // { name: '体剖切，右键结束', value: 'bodycutting', selected: 'inbodycutting', checked: false }
    ],
    modelAnimation: [
        // { name: '自旋转动画', value: 'zrotate', selected: 'inzrotate', checked: false },
    ]
}
export {
    ETopPapers, Effect, EInteraction, operateList
}