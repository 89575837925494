<template>
    <router-view />
</template>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
}
</style>
<style>
* {
    padding: 0px;
    margin: 0px;
    list-style: none;

}

.selectBox {
    border: 1px solid #55aaff;
    background-color: rgba(75, 160, 255, 0.3);
    position: fixed;
}

.mark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgb(255, 145, 0);
}

.tag {
    padding: 2px 12px;
    background: rgb(255, 145, 0);
    border-radius: 5px;
    color: white;
    font-size: 14px;
}

.mark_active {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgb(255, 145, 0);
    border: 3px solid white;
}

.tag_active {
    padding: 2px 12px;
    background: rgb(255, 145, 0);
    border-radius: 5px;
    color: white;
    font-size: 14px;
    border: 3px solid white;
}
</style>